
import { defineComponent } from 'vue'
import {
  IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonItem, IonList, IonLabel, IonCard, IonButton
} from '@ionic/vue'
import store from '@/store'
import router from '@/router'
import { BackendMixin } from '@/mixins/backend'
import { loadFranceConnectLogout, storeFranceConnectLogout } from '@/types/france_connect'

export default  defineComponent({
  name: 'AboutTab',
  mixins: [BackendMixin],
  components: {
    IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonItem, IonList, IonLabel, IonCard,
    IonButton,
  },
  computed: {
    userEmail() {
      return store.getters.userEmail
    },
    userName() {
      return store.getters.userName
    },
    backendUrl() {
      return store.getters.backendUrl
    },
    version() {
      return process.env.VUE_APP_VERSION
    },
    structure() {
      return store.getters.structure
    },
    cguUrl() {
      return (store.getters.structure && store.getters.structure.cgu) || ''
    },
    cguLink() {
      if (this.cguUrl) {
        return '<a href="' + this.cguUrl + '" target="_blank">Conditions générales d\'utilisation</a>'
      }
      return ''
    },
    contactEmail() {
      return (store.getters.structure && store.getters.structure.email) || 'support@millibase.net'
    },
  },
  methods: {
    contactUs() {
      window.location.href = 'mailto:' + this.contactEmail
    },
    onLogout() {
      this.logout().then(
        () => {
          router.push({ name: 'login', }).then(
            () => {
              const logoutUrl = loadFranceConnectLogout()
              if (logoutUrl) {
                storeFranceConnectLogout('') // reset data
                window.location.href = logoutUrl
              } else {
                window.location.reload()
              }
            }
          )
        }
      )
    },
  },
})
